import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "./layout"
import SEO from "./seo"

import Header from "./header"

import SignupForm from "../components/signupform"
import SocialIcon from "../components/socialicon"

import moment from 'moment'

const MarkdownTemplate = function({data}) {

  // console.log(data);

  let bannerTemplate = {
    color: 'white',
    backgroundImage: 'url(/' + data.markdownRemark.frontmatter.image + ')',
    padding: '16rem 0rem',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center'
  };

  let bannerParse = {};

  try {
    bannerParse = JSON.parse(data.markdownRemark.frontmatter.banner)
  } catch {}

  const bannerStyle = Object.assign(bannerTemplate,bannerParse);


  return (
  <Layout>
    <SEO
      title={data.markdownRemark.frontmatter.title}
      description={data.markdownRemark.frontmatter.description}
      image={'/'+data.markdownRemark.frontmatter.image}
      />
    <Header/>


    <div class="section" style={bannerStyle}>
      <div class="container">
      </div>
    </div>

    <div class={'section blog ' + (data.markdownRemark.frontmatter.sectionclass ? data.markdownRemark.frontmatter.sectionclass : '')}>
    <div class="container">
      <div class="two columns">&nbsp;</div>
      <div class="eight columns">

        <h1>{data.markdownRemark.frontmatter.title}</h1>

        <div className="readtime">{data.markdownRemark.frontmatter.duration} read</div>

        <p className="details">
        <div className="author">By {data.markdownRemark.frontmatter.author}</div>
        <div className="date">{moment(data.markdownRemark.frontmatter.date).format('DD MMMM YYYY')}</div>
        </p>

        <span
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: `${data.markdownRemark.html}` }}
        />


          <div className="shareContainer">
            <SocialIcon icon="facebook" url={"https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent("https://herfinancialjourney.com/blog/"+data.markdownRemark.frontmatter.path)} share="Share"/>
            <SocialIcon icon="twitter" url={"https://twitter.com/intent/tweet?text="+encodeURIComponent(data.markdownRemark.frontmatter.title)+" "+encodeURIComponent("https://herfinancialjourney.com/blog/"+data.markdownRemark.frontmatter.path)} share="Tweet"/>
            <SocialIcon icon="linkedin" url={"https://www.linkedin.com/sharing/share-offsite/?&title="+encodeURIComponent(data.markdownRemark.frontmatter.title)+"&summary="+encodeURIComponent(data.markdownRemark.frontmatter.description)+"&url="+encodeURIComponent("https://herfinancialjourney.com/blog/"+data.markdownRemark.frontmatter.path)} share="Share"/>
          </div>

        </div>
      </div>
    </div>


    <div class="section signup_blog">
      <div class="container">
        <div class="row">
          <div class="two columns">&nbsp;</div>
          <div class="eight columns">

            <h5>Before you go...</h5>
            <p>
            We&apos;d love you to be part of Her Financial Journey. Sign up and be notified of our latest content, right in your inbox.
            </p>
            <SignupForm/>

            <p>
              <Link to="/blog">Read the latest posts</Link>
            </p>

          </div>
          <div class="one-half column">
          </div>
        </div>
      </div>
    </div>

  </Layout>
  )
}

// BlogTemplate.defaultProps = {
//   markdownRemark: {
//     image: null,
//     bannerStyle: null,
//     html: "<h1>fuck</h1>",
//     frontmatter: {
//       title: null,
//       description: null,
//       duration: null,
//       author: null,
//       date: null
//     }
//   }
// };

export const query =
  graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
        html
        frontmatter {
          date
          path
          title
          author
          description
          duration
          image
          banner
          sectionclass
        }
      }
    }
  `


export default MarkdownTemplate
